import { Component, Vue, Watch } from 'vue-property-decorator';
import { Settings, DateTime } from 'luxon';
import { Organization } from '@/models/Organization';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import PlanningExpertCalendar from '@/components/planningtool/planning-expert-dialog/PlanningExpertCalendar.vue';
import { PlanningValidationPayload } from '@/views/PlanningTool/PlanningTool';
import { slotLabelFormat, columnsHeaderFormat, resourceCalendarPlugins } from '@/support/FullcalendarSettings';
import { User, ResourceUser } from '@/models/User';
import { Event as EventModel, EventType, EventTypeLabels } from '@/models/Event';
import { firstDayOfWeek, lastDayOfWeek, setFormattedDatePickerValue, isValidDate, dateErrorMessage } from '@/support/String';
import { EventRender, renderCustomEvent, renderSimpleEvent } from '@/support/CalendarEventRenderFunctions';
import { cloneDeep } from 'lodash';
import { Department } from '@/models/Department';
import { Skill } from '@/models/Skill';
import { UserLevelItem, UserLevels } from '@/models/User';
import { AppointmentLimit } from '@/models/AppointmentLimit';

@Component<OrganizationPlanning>({
  components: {
    PlanningExpertCalendar,
  },
})
export default class OrganizationPlanning extends Vue {
  public $pageTitle = "Bedrijfsagenda's";

  protected organizations: Organization[] | null = null;

  protected users: User[] = [];

  protected resourceUsers: ResourceUser[] = [];

  protected resourceEvents: any[] = [];

  protected isLoading = true;

  protected date = '';

  protected selectedToggle = 'week';

  protected clickCount = 0;

  protected clickTimer: NodeJS.Timeout | null = null;

  protected activeOrganization = '';

  protected userLevels: UserLevelItem[] = UserLevels;

  protected departments: Department[] = [];

  protected skills: Skill[] = [];

  protected calendarFilters: CalendarFilters = {};

  // appointmentLimits
  protected appointmentLimits: AppointmentLimit[] = [];

  protected currentWeek: DateTime[] = [];

  protected isDisplayingLimitDialog = false;

  protected mounted() {
    Settings.defaultLocale = 'nl';
    this.date = DateTime.local().toFormat('yyyy-LL-dd');
    this.initialize();
  }

  protected async initialize() {
    this.isLoading = true;
    if (this.$store.state.isServiceOrganization) {
      await this.getOrganizations();
    } else {
      this.activeOrganization = this.$store.state.Auth.organization.id;
      this.organizations = [cloneDeep(this.$store.state.Auth.organization)];
      await this.getDepartments();
      await this.getSkills();
      await this.getUsers();
    }
    this.isLoading = false;
  }

  protected initAppointmentDialogs() {
    this.resourceUsers.forEach((user: ResourceUser) => {
      const target = document.querySelectorAll(`[data-resource-id='${user.id}']`);

      if (! target.length) { return; }

      target[0].removeEventListener('mouseenter', this.openLimitDialog);
      target[0].removeEventListener('mouseleave', this.closeLimitDialog);
      target[0].addEventListener('mouseenter', this.openLimitDialog);
      target[0].addEventListener('mouseleave', this.closeLimitDialog);
    });
  }

  protected openLimitDialog(event: any) {
    this.isDisplayingLimitDialog = true;
    event.target.className = 'appointment-limit';
    const dialog = document.createElement('div');
    dialog.className = 'appointment-limit__dialog';
    const child = event.target.children;
    dialog.innerHTML = this.appointmentDialog(event.target.getAttribute('data-resource-id'));
    child[0].appendChild(dialog);
  }

  protected closeLimitDialog(event: any) {
    if (! this.isDisplayingLimitDialog) {
      return;
    }

    this.isDisplayingLimitDialog = false;
    const element = document.querySelectorAll('.appointment-limit__dialog');
    const child = event.target.children;
    if (! child || ! child.length || ! element) {
      return;
    }

    child[0].removeChild(element[0]);
  }

  protected appointmentDialog(id: string): string {
    const user = this.users.find((user: User) => user.uuid === id);

    if (! user) {
      return '';
    }

    const userAppointmentLimits = this.appointmentLimits.filter((appointmentLimit: AppointmentLimit) => appointmentLimit.user?.uuid === id);

    return `<div>
      <h3>Max afspraken</h3>
      <ul>
        <li>
          <span>maandag:</span>
          <span>${this.findAppointmentLimit(userAppointmentLimits || null, 1)}</span>
        </li>
        <li>
          <span>dinsdag:</span>
          <span>${this.findAppointmentLimit(userAppointmentLimits || null, 2)}</span>
        </li>
        <li>
          <span>woensdag:</span>
          <span>${this.findAppointmentLimit(userAppointmentLimits || null, 3)}</span>
        </li>
        <li>
          <span>donderdag:</span>
          <span>${this.findAppointmentLimit(userAppointmentLimits || null, 4)}</span>
        </li>
        <li>
          <span>vrijdag:</span>
          <span>${this.findAppointmentLimit(userAppointmentLimits || null, 5)}</span>
        </li>
        <li>
          <span>zaterdag:</span>
          <span>${this.findAppointmentLimit(userAppointmentLimits || null, 6)}</span>
        </li>
        <li>
          <span>zondag:</span>
          <span>${this.findAppointmentLimit(userAppointmentLimits || null, 7)}</span>
        </li>
      </ul>
    </div>`;
  }

  protected findAppointmentLimit(appointments: AppointmentLimit[] | null, day: number) {
    if (! appointments) { return '-'; }

    const appointment = appointments?.find((appointment: AppointmentLimit) => {
      if (! appointment.date) { return; }
      return DateTime.fromSQL(appointment.date).weekday === day;
    });

    return appointment ? appointment.appointment_limit : '-';
  }

  protected async getDepartments() {
    await new Department()
      .all()
      .then((departments: Department[]) => {
        this.departments = departments;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async getSkills() {
    await new Skill()
      .all()
      .then((skills: Skill[]) => {
        this.skills = skills;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async getOrganizations() {
    await new Organization()
      .all()
      .then((organizations: Organization[]) => {
        this.organizations = organizations;

        if (this.organizations.length && ! this.activeOrganization) {
          this.activeOrganization = this.organizations[0].id || '';
        }
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    await this.getDepartments();
    await this.getSkills();
    await this.getUsers();
  }

  protected destroyed() {
    this.$store.dispatch('updateSelectedPlanningDate', '');
  }

  protected async getUsers() {
    this.users = [];
    this.isLoading = true;

    const payload: {[key: string]: any} = {
      is_active: true,
      is_plannable: true,
      schedule_date_spoof: this.date,
    };

    const users = await new User()
      .dmz(this.activeOrganization)
      .limit(500)
      .include(['schedules'])
      .filter(payload)
      .filter(this.calendarFilters)
      .sort('name', 'ASC')
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    this.users = users;
    const resourceUsers = this.parseUsersToResourceUsers(users);
    this.resourceUsers = resourceUsers;
    await this.getEvents();
    this.appointmentLimits = await this.fetchAppointmentLimits();
    this.isLoading = false;

    if (this.selectedToggle !== 'month') {
      this.initAppointmentDialogs();
    }
  }

  protected splitUsersInChunks() {
    const size = 20;
    const userChunks = [];
    for (let i = 0; i < this.users.length; i += size) {
      userChunks.push(this.users.slice(i, i + size));
    }
    return userChunks;
  }

  protected async fetchAppointmentLimits(): Promise<AppointmentLimit[]> {
    const userChunks = this.splitUsersInChunks();

    let limits: AppointmentLimit[] = [];

    for (let i = 0; i < userChunks.length; i += 1) {
      const userChunk = userChunks[i];

      const limitGroup = await new AppointmentLimit().dmz(this.activeOrganization)
        .filter({
          week: this.date,
          users: userChunk.map((expert: User) => expert.uuid),
        })
        .include('user')
        .all()
        .catch((error: AxiosError) => {
          ErrorHandler.network(error);
          return [];
        });

      limits = [...limits, ...limitGroup];
    }

    return limits;
  }

  protected parseUsersToResourceUsers(users: User[]): ResourceUser[] {
    const resourceUsers:ResourceUser[] = [];

    users.forEach((user: User) => {
      const resourceUser: ResourceUser = user.parseToResourceUser();
      resourceUser.businessHours = user.parseScheduleToBusinessHours();
      resourceUsers.push(resourceUser);
    });

    return resourceUsers;
  }

  protected async getEvents() {
    this.resourceEvents = [];
    const events = await new EventModel()
      .dmz(this.activeOrganization)
      .include('report')
      .filter(this.eventsFilter)
      .limit(5000)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    if (events) {
      this.parseEvents(events);
    }
  }

  protected parseEvents(events: EventModel[]) {
    this.resourceEvents = [];
    events.forEach((event: EventModel) => {
      const fullcalendarEvent: {[key: string]: any} | null = event.fullcalendarEvent;
      if (! fullcalendarEvent) { return; }

      fullcalendarEvent.resourceIds = [event.user_id];
      fullcalendarEvent.extendedProps = {
        type: event.type,
        appointment_type: event.appointment_type,
        user: {
          name: '',
          id: event.user_id,
        },
        organization_id: event.organization_id,
        report: event.report,
        note: event.note,
      };

      if (event.type !== 'appointment') {
        fullcalendarEvent.title = this.eventTypeLabels[event.type as string];
      }

      this.resourceEvents.push(fullcalendarEvent);
    });
  }

  protected renderEvent(eventInfo: EventRender) {
    if (eventInfo && eventInfo.event && eventInfo.event.extendedProps && eventInfo.event.extendedProps.type === 'appointment') {
      renderCustomEvent(eventInfo);
    } else {
      renderSimpleEvent(eventInfo);
    }
  }

  protected goToPreviousDate() {
    if (this.isLoading) {
      return;
    }
    const calendars = Object.keys(this.$refs).filter((key: string) => key.includes('fullcalendar'));

    calendars.forEach((key: string) => {
      const calendar = this.$refs[key] as any;
      if (calendar && calendar.length && this.date) {
        const previousWeek = DateTime.fromSQL((this.date as string)).minus(this.eventTypeValueSwitch);
        this.date = previousWeek.toFormat('yyyy-MM-dd');
        calendar[0].getApi().gotoDate(this.date);
        this.getUsers();
      }
    });
  }

  protected goToNextDate() {
    if (this.isLoading) {
      return;
    }
    const calendars = Object.keys(this.$refs).filter((key: string) => key.includes('fullcalendar'));

    calendars.forEach((key: string) => {
      const calendar = this.$refs[key] as any;
      if (calendar && calendar.length && this.date) {
        const nextWeek = DateTime.fromSQL((this.date as string)).plus(this.eventTypeValueSwitch);
        this.date = nextWeek.toFormat('yyyy-MM-dd');

        calendar[0].getApi().gotoDate(this.date);
        this.getUsers();
      }
    });
  }

  protected goToToday() {
    const calendars = Object.keys(this.$refs).filter((key: string) => key.includes('fullcalendar'));

    calendars.forEach((key: string) => {
      const calendar = this.$refs[key] as any;
      if (calendar && calendar.length && this.date) {
        this.date = DateTime.local().toFormat('yyyy-LL-dd');
        calendar[0].getApi().gotoDate(this.date);
        this.getUsers();
      }
    });
  }

  protected goToDate() {
    const calendars = Object.keys(this.$refs).filter((key: string) => key.includes('fullcalendar'));

    calendars.forEach((key: string) => {
      const calendar = this.$refs[key] as any;
      if (calendar && calendar.length && this.date) {
        calendar[0].getApi().gotoDate(this.date);
        this.getUsers();
      }
    });
  }

  protected selectToggle(type: string) {
    this.selectedToggle = type;
  }

  protected setCalendarView() {
    const calendars = Object.keys(this.$refs).filter((key: string) => key.includes('fullcalendar'));

    calendars.forEach((key: string) => {
      const calendar = this.$refs[key] as any;
      if (calendar.length) {
        calendar[0].getApi().changeView(this.calendarView);
        calendar[0].getApi().gotoDate(this.date);

        this.$nextTick(() => {
          this.initAppointmentDialogs();
        });
      }
    });
  }

  protected handleEventClick(eventInfo: any) {
    this.clickCount += 1;

    if (this.clickCount === 1) {
      this.clickTimer = setTimeout(() => {
        this.clickCount = 0;
      }, 200);
    } else if (this.clickCount === 2) {
      clearTimeout(this.clickTimer as any);
      this.clickCount = 0;
      this.handleDoubleClick(eventInfo);
    }
  }

  protected handleDoubleClick(eventInfo: any) {
    const event = eventInfo.event;

    if (! event || ! event.extendedProps || ! event.extendedProps.report || ! event.extendedProps.report.id) {
      return;
    }

    window.open(`${window.location.origin}/reports/${event.extendedProps.report.id}`);
  }

  // Date
  protected isEditingDate = false;

  protected dateFormatted: string | null = null;

  protected dateErrorMessage = '';

  protected formatDateFromDatePicker() {
    if (this.date) {
      this.dateFormatted = setFormattedDatePickerValue(this.date as string, 'yyyy-LL-dd', 'dd-LL-yyyy');
      this.dateErrorMessage = ! isValidDate(this.dateFormatted) ? dateErrorMessage : '';
    }
  }

  protected formatDateFromTextField(value: string) {
    this.dateErrorMessage = ! isValidDate(value) ? dateErrorMessage : '';
    this.date = setFormattedDatePickerValue(value);
  }

  @Watch('date')
  protected dateChanged() {
    this.formatDateFromDatePicker();
  }

  @Watch('isEditingDate')
  protected isEditingDateChanged() {
    if (! this.isEditingDate) {
      this.formatDateFromDatePicker();
    }
  }

  // Getters
  protected get defaultDate() {
    return this.date as string;
  }

  protected set defaultDate(date: string) {
    this.date = date;
  }

  protected get resourceTimelineHeader() {
    return {
      left: 'title',
      center: '',
      right: '',
    };
  }

  private get eventsFilter() {
    const filter: {[key: string]: any} = {
       types: [
        EventType.APPOINTMENT,
        EventType.SICK,
        EventType.HOLIDAY,
        EventType.MEETING,
        EventType.HOUSEVISIT,
        EventType.SPEAKINGROOM,
        EventType.COMMUNITYCENTER,
        EventType.LOSSOFNONRESIDENTIALPROPERTYVALUE,
        EventType.MANURECELLAR,
        EventType.OTHER,
      ],
      not_cancelled: true,
      company_schedule: true,
    };

    if (this.date) {
      if (this.selectedToggle === 'month') {
        const year = DateTime.fromFormat(this.date as string, 'yyyy-LL-dd').toFormat('yyyy');
        const month = DateTime.fromFormat(this.date as string, 'yyyy-LL-dd').toFormat('LL');
        const lastDayOfMonth = DateTime.local(Number(year), Number(month)).daysInMonth;

        filter.period = [
          `${DateTime.fromFormat(this.date as string, 'yyyy-LL-dd').toFormat('yyyy-LL')}-01`,
          `${year}-${month}-${lastDayOfMonth}`,
        ];
      } else {
        filter.period = [
          firstDayOfWeek(DateTime.fromFormat(this.date as string, 'yyyy-LL-dd')).toFormat('yyyy-LL-dd'),
          lastDayOfWeek(DateTime.fromFormat(this.date as string, 'yyyy-LL-dd')).toFormat('yyyy-LL-dd'),
        ];
      }
    }

    return filter;
  }

  protected get resourceLabelText() {
    if (! this.organizations) {
      return 'Deskundigen';
    }
    const selectedOrganization = this.organizations.find((organization: Organization) => organization.id === this.activeOrganization);

    return selectedOrganization && selectedOrganization.name && selectedOrganization.name.toLowerCase() === 'img' ? 'Zaakbegeleiders' : 'Deskundigen';
  }

  protected get weekNumber() {
    const date = DateTime.fromSQL(this.date as string);
    return date ? date.weekNumber : '';
  }

  protected get headerDate() {
    const date = DateTime.fromSQL(this.date as string);
    return date ? `(${firstDayOfWeek(date).toFormat('dd-LL-yyyy')} t/m ${lastDayOfWeek(date).toFormat('dd-LL-yyyy')})` : '';
  }

  protected get eventTypeLabels() {
    return EventTypeLabels;
  }

  protected get calendarView() {
    if (this.selectedToggle === 'day') {
      return 'resourceTimelineDay';
    }
    if (this.selectedToggle === 'week') {
      return 'resourceTimelineWeek';
    }
    return 'resourceTimelineMonth';
  }

  protected get eventTypeValueSwitch() {
    if (this.selectedToggle === 'day') {
      return { days: 1 };
    }
    if (this.selectedToggle === 'week') {
      return { weeks: 1 };
    }
    return { months: 1 };
  }

  protected get slotDuration() {
    return { minutes: 30 };
  }

  protected get slotLabelFormat() {
    return slotLabelFormat;
  }

  protected get columnsHeaderFormat() {
    return columnsHeaderFormat;
  }

  protected get calendarPlugins() {
    return resourceCalendarPlugins;
  }

  protected eventChanged(validationPayload: PlanningValidationPayload) {
    this.$emit('eventChanged', validationPayload);
    // this.closeDialog();
  }

  @Watch('activeOrganization')
  protected activeOrganizationChanged() {
    this.calendarFilters = {};
    this.initialize();
  }

  @Watch('selectedToggle')
  protected selectedToggleChanged() {
    this.setCalendarView();
    this.getUsers();
  }
}

interface CalendarFilters {
  departments?: Department[];
  skills?: Skill[];
  userLevels?: UserLevelItem[];
}
