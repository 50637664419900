var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v("Bedrijfsagenda's")
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-tabs",
                {
                  attrs: { "slider-color": "secondary" },
                  model: {
                    value: _vm.activeOrganization,
                    callback: function($$v) {
                      _vm.activeOrganization = $$v
                    },
                    expression: "activeOrganization"
                  }
                },
                [
                  _vm._l(_vm.organizations, function(organization, index) {
                    return [
                      _c(
                        "v-tab",
                        {
                          key: "planning-tab-" + index,
                          staticClass: "tab__filled",
                          class: {
                            active: _vm.activeOrganization === organization.id
                          },
                          attrs: {
                            href: "#" + organization.id,
                            disabled: _vm.isLoading
                          }
                        },
                        [_vm._v(" " + _vm._s(organization.name) + " ")]
                      )
                    ]
                  }),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.activeOrganization,
                        callback: function($$v) {
                          _vm.activeOrganization = $$v
                        },
                        expression: "activeOrganization"
                      }
                    },
                    [
                      _vm._l(_vm.organizations, function(organization) {
                        return [
                          _c(
                            "v-tab-item",
                            {
                              key: "planning-tab-content-" + organization.id,
                              attrs: {
                                lazy: true,
                                transition: false,
                                "reverse-transition": false,
                                value: organization.id
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "elementPanel" },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { "justify-space-between": "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        { staticClass: "flex-starving" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "weekChangeContainer"
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass:
                                                    "weekChange weekChange__prev",
                                                  on: {
                                                    click: _vm.goToPreviousDate
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " keyboard_arrow_left "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "h2",
                                                {
                                                  staticClass:
                                                    "elementSubTitle elementSubTitle__accent"
                                                },
                                                [
                                                  _vm._v(
                                                    " Week " +
                                                      _vm._s(_vm.weekNumber) +
                                                      " "
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "header-date"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.headerDate)
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass:
                                                    "weekChange weekChange__next",
                                                  on: {
                                                    click: _vm.goToNextDate
                                                  }
                                                },
                                                [_vm._v("keyboard_arrow_right")]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { shrink: "" } },
                                        [
                                          _c(
                                            "v-menu",
                                            {
                                              ref: "dialog",
                                              refInFor: true,
                                              attrs: {
                                                "close-on-content-click": false,
                                                lazy: "",
                                                "nudge-right": "100",
                                                "full-width": "",
                                                "max-width": "290px",
                                                "min-width": "290px"
                                              },
                                              model: {
                                                value: _vm.isEditingDate,
                                                callback: function($$v) {
                                                  _vm.isEditingDate = $$v
                                                },
                                                expression: "isEditingDate"
                                              }
                                            },
                                            [
                                              _c("v-text-field", {
                                                staticClass: "input input-date",
                                                attrs: {
                                                  slot: "activator",
                                                  placeholder: "DD-MM-YYYY",
                                                  label: "Kies een datum",
                                                  "error-messages":
                                                    _vm.dateErrorMessage,
                                                  "prepend-icon": "today",
                                                  disabled: _vm.isLoading
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.formatDateFromTextField(
                                                      $event
                                                    )
                                                  },
                                                  "click:prepend": _vm.goToToday
                                                },
                                                slot: "activator",
                                                model: {
                                                  value: _vm.dateFormatted,
                                                  callback: function($$v) {
                                                    _vm.dateFormatted = $$v
                                                  },
                                                  expression: "dateFormatted"
                                                }
                                              }),
                                              _c("v-date-picker", {
                                                ref: "picker",
                                                refInFor: true,
                                                attrs: {
                                                  "first-day-of-week": "1",
                                                  locale: "nl-nl",
                                                  min: "1910-01-01",
                                                  color: "#837f16"
                                                },
                                                on: {
                                                  change: function($event) {
                                                    _vm.isEditingDate = false
                                                    _vm.goToDate()
                                                  }
                                                },
                                                model: {
                                                  value: _vm.defaultDate,
                                                  callback: function($$v) {
                                                    _vm.defaultDate = $$v
                                                  },
                                                  expression: "defaultDate"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { staticClass: "flex-starving" },
                                        [
                                          _c("v-layout", [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "toolbar__toggle toolbar__toggle--primary",
                                                class: {
                                                  selected:
                                                    _vm.selectedToggle &&
                                                    _vm.selectedToggle === "day"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.selectToggle(
                                                      "day"
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(" Day ")]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "toolbar__toggle toolbar__toggle--primary",
                                                class: {
                                                  selected:
                                                    _vm.selectedToggle &&
                                                    _vm.selectedToggle ===
                                                      "week"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.selectToggle(
                                                      "week"
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(" Week ")]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "toolbar__toggle toolbar__toggle--primary-dark",
                                                class: {
                                                  selected:
                                                    _vm.selectedToggle &&
                                                    _vm.selectedToggle ===
                                                      "month"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.selectToggle(
                                                      "month"
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(" Maand ")]
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-layout",
                                    { attrs: { wrap: "", "align-center": "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { xs3: "" } },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "with-border",
                                            attrs: {
                                              items: _vm.departments,
                                              "item-value": "id",
                                              "item-text": "name",
                                              "hide-details": "",
                                              placeholder: "Afdelingen",
                                              clearable: "",
                                              multiple: "",
                                              disabled: _vm.isLoading
                                            },
                                            on: { change: _vm.getUsers },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "selection",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    var index = ref.index
                                                    return [
                                                      index === 0
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.name
                                                              ) + " "
                                                            )
                                                          ])
                                                        : _vm._e(),
                                                      index === 1
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "grey--text caption"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " (+" +
                                                                  _vm._s(
                                                                    _vm
                                                                      .calendarFilters
                                                                      .departments
                                                                      .length -
                                                                      1
                                                                  ) +
                                                                  " andere) "
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value:
                                                _vm.calendarFilters.departments,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.calendarFilters,
                                                  "departments",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "calendarFilters.departments"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "custom-filter",
                                          attrs: { xs3: "" }
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.skills,
                                              "item-text": "name",
                                              "item-value": "id",
                                              "hide-details": "",
                                              placeholder: "Competenties",
                                              clearable: "",
                                              multiple: "",
                                              disabled: _vm.isLoading
                                            },
                                            on: { change: _vm.getUsers },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "selection",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    var index = ref.index
                                                    return [
                                                      index === 0
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.name
                                                              ) + " "
                                                            )
                                                          ])
                                                        : _vm._e(),
                                                      index === 1
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "grey--text caption"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " (+" +
                                                                  _vm._s(
                                                                    _vm
                                                                      .calendarFilters
                                                                      .skills
                                                                      .length -
                                                                      1
                                                                  ) +
                                                                  " andere) "
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: _vm.calendarFilters.skills,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.calendarFilters,
                                                  "skills",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "calendarFilters.skills"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "custom-filter ml-3",
                                          attrs: { xs3: "" }
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.userLevels,
                                              "item-text": "name",
                                              "item-value": "value",
                                              "hide-details": "",
                                              placeholder: "Niveau",
                                              clearable: "",
                                              multiple: "",
                                              disabled: _vm.isLoading
                                            },
                                            on: { change: _vm.getUsers },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "selection",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    var index = ref.index
                                                    return [
                                                      index === 0
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.name
                                                              ) + " "
                                                            )
                                                          ])
                                                        : _vm._e(),
                                                      index === 1
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "grey--text caption"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " (+" +
                                                                  _vm._s(
                                                                    _vm
                                                                      .calendarFilters
                                                                      .levels
                                                                      .length -
                                                                      1
                                                                  ) +
                                                                  " andere) "
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: _vm.calendarFilters.levels,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.calendarFilters,
                                                  "levels",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "calendarFilters.levels"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.isLoading
                                    ? _c(
                                        "v-layout",
                                        { attrs: { "justify-center": "" } },
                                        [
                                          _vm.isLoading
                                            ? _c("LoaderCard", {
                                                staticClass: "main-loader",
                                                attrs: {
                                                  extraClass: "availability",
                                                  type: "spinner",
                                                  flat: true
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.activeOrganization === organization.id &&
                                  !_vm.isLoading
                                    ? _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            wrap: "",
                                            "justify-start": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs12: "" } },
                                            [
                                              !_vm.isLoading
                                                ? _c("FullCalendar", {
                                                    ref:
                                                      "fullcalendar-" +
                                                      _vm.activeOrganization,
                                                    refInFor: true,
                                                    attrs: {
                                                      plugins:
                                                        _vm.calendarPlugins,
                                                      defaultDate:
                                                        _vm.defaultDate,
                                                      defaultView:
                                                        _vm.calendarView,
                                                      resourceLabelText:
                                                        _vm.resourceLabelText,
                                                      nowIndicator: true,
                                                      selectable: false,
                                                      selectMirror: false,
                                                      editable: false,
                                                      slotDuration:
                                                        _vm.slotDuration,
                                                      locale: "nl",
                                                      height: 900,
                                                      header:
                                                        _vm.resourceTimelineHeader,
                                                      resources:
                                                        _vm.resourceUsers,
                                                      events:
                                                        _vm.resourceEvents,
                                                      allDaySlot: false,
                                                      minTime: "06:00",
                                                      maxTime: "20:00",
                                                      firstDay: 1
                                                    },
                                                    on: {
                                                      eventRender:
                                                        _vm.renderEvent,
                                                      eventClick: function(
                                                        $event
                                                      ) {
                                                        return _vm.handleEventClick(
                                                          $event
                                                        )
                                                      }
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      })
                    ],
                    2
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }